
export const setTab = tab => {
  return {
    type: "SET_TAB",
    tab
  };
};

export const setStatus = status => {
  return {
    type: "SET_STATUS",
    status
  };
};

