import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage"

var firebaseConfig = {
  apiKey: "AIzaSyBSxFIYkF1eLWjI16jvCw_h9IAAXmmb1es",
  authDomain: "dairyhome-dcea9.firebaseapp.com",
  databaseURL: "https://dairyhome-dcea9.firebaseio.com",
  projectId: "dairyhome-dcea9",
  storageBucket: "dairyhome-dcea9.appspot.com",
  messagingSenderId: "462196331184",
  appId: "1:462196331184:web:77263f5bbb281548"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const db = firebase.firestore();
export const storage = firebase.storage();
export default firebase;