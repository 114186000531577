const initstate = {
  zones: [
    { name: "A", status: "เปิด" },
    { name: "E", status: "เปิด" },
    { name: "D", status: "เปิด" }
  ]
};

export default function(state = initstate, action) {
  switch (action.type) {
    case "CREATE_ZONE":
      return {
        ...state,
        items: action.payload
      };
    case "FETCH_ZONE":
      return {
        ...state,
        zones: action.payload.zones
      };
    default:
      return state;
  }
}
