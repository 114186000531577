import foodReducer from "./foodReducer";
import tabReducer from "./tabReducer";
import zoneReducer from "./zoneReducer";
import orderReducer from "./orderReducer";
import promotionReducer from "./promotionReducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  food: foodReducer,
  tab: tabReducer,
  zone: zoneReducer,
  order: orderReducer,
  promo: promotionReducer,
  firebase:firebaseReducer,
  firestore: firestoreReducer
});
export default rootReducer;
