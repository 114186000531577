import React, { Component } from "react";
import Counter from "./Counter";

import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { openModal } from "../../../store/action/orderAction";
import { db } from "../../../config/fbConfig";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickViewProdcut: {},
      quantity: 1,
      isAdded: false
    };
  }
  addToCart(image, name, price, id, quantity, category) {
    let totalQuantity = quantity;
    function isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
    let last = 1;
    if (!isEmpty(this.props.cart[this.props.tab])) {
      for (var i in this.props.cart[this.props.tab]) {
        if (this.props.cart[this.props.tab][i].id === id) {
          last = i;
          totalQuantity += this.props.cart[this.props.tab][i].quantity;
          break;
        } else {
          last = parseInt(i) + 1;
        }
      }
    }
    db.collection("cart")
      .doc(this.props.tab)
      .update({
        [last]: {
          id,
          quantity: totalQuantity,
          status:"request"
        }
      });

    this.setState(
      {
        isAdded: true
      },
      function() {
        setTimeout(() => {
          this.setState({
            isAdded: false,
            selectedProduct: {}
          });
        }, 3500);
      }
    );
  }

  quickView(image, name, price, id, category) {
    this.setState(
      {
        quickViewProdcut: {
          image: image,
          name: name,
          price: price,
          id: id,
          category: category
        }
      },
      function() {
        this.props.openModal(this.state.quickViewProdcut);
      }
    );
  }
  updateQuantity = quantity => {
    this.setState({
      quantity
    });
  };
  render() {
    let { image, name, price, id, category } = this.props;
    let { quantity } = this.state;
    return (
      <div className="product">
        <div className="product-image">
          <img
            src={image}
            alt={this.props.name}
            onClick={this.quickView.bind(
              this,
              image,
              name,
              price,
              id,
              category,
              quantity
            )}
          />
        </div>
        <h4 className="product-name">{this.props.name}</h4>
        <p className="product-price">{this.props.price}</p>

        <Counter
          productQuantity={quantity}
          updateQuantity={this.updateQuantity}
        />
        <div className="product-action">
          <button
            className={!this.state.isAdded ? "" : "added"}
            type="button"
            onClick={this.addToCart.bind(
              this,
              image,
              name,
              price,
              id,
              quantity,
              category
            )}
          >
            {!this.state.isAdded ? "ADD TO CART" : "✔ ADDED"}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModal: product => dispatch(openModal(product))
  };
};

const mapStateToProps = state => {
  return {
    cartLoad: state.order.cart,
    cart: state.firestore.data.cart,
    tab: state.tab.currentTab
  };
};
export default compose(
  firestoreConnect(["cart", "tab"]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Product);
