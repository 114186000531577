export const changeMode = () => {
  return {
    type: "CHANGE_MODE"
  };
};
export const changeOverlay = () => {
  return {
    type: "CHANGE_OVERLAY"
  };
};
export const createOrder = staff => {
  return {
    type: "CREATE_ORDER",
    staff
  };
};
export const setCurrentFood = food => {
  return {
    type: "SET_CURRENT_FOOD",
    food
  };
};
export const changeCurrentTable = tab => {
  return {
    type: "CHANGE_CURRENT_TABLE",
    tab
  };
};
export const increaseAddedQuantity = key => {
  return {
    type: "INCREASE_ADDED_QUANTITY",
    key
  };
};
export const setTerm = term => {
  return {
    type: "SET_TERM",
    term: term.target.value
  };
};
export const resetTerm = () => {
  return {
    type: "RESET_TERM"
  };
};
export const setTermCategory = term => {
  return {
    type: "SET_TERM",
    term
  };
};
export const decreaseAddedQuantity = key => {
  return {
    type: "DECREASE_ADDED_QUANTITY",
    key
  };
};
export const deleteAdded = key => {
  return {
    type: "DELETE_ADDED",
    key
  };
};
export const clearStaff = () => {
  return {
    type: "CLEAR_STAFF"
  };
};
export const openModal = product => {
  return {
    type: "OPEN_MODAL",
    product
  };
};
export const closeModal = () => {
  return {
    type: "CLOSE_MODAL"
  };
};
export const addToCart = () => {
  return {
    type: "ADD_TO_CART"
  };
};
