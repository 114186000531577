const initstate = {
  tabs: [
    { name: "A1", group: "A", status: "ว่าง", size: "2" },
    { name: "A2", group: "A", status: "ว่าง", size: "4" }
  ],
  currentTab: "",
  status:""
};

export default function(state = initstate, action) {
  switch (action.type) {
    case "SET_TAB":
      return {
        ...state,
        currentTab: action.tab
      };
      case "SET_STATUS":
      return {
        ...state,
        status: action.status
      };
    case "FETCH_TAB":
      return { ...state, tabs: action.payload.tabs };
    default:
      return state;
  }
}
