import React, { Component } from "react";

import { Icon } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

class Bill extends Component {
  render() {
    let { status } = this.props;
    let bill = <div></div>;
    switch (status) {
      case "เก็บเงิน":
        bill = (
          <div className="bg">
            <div className="bill-bg">
              <Icon type="loading" className="top" />
              <h1 className="center">Please Wait</h1>
            </div>
          </div>
        );
        break;
      case "ปริ๊น":
        bill = (
          <div className="bg">
            <div className="bill-bg">
              <Icon type="audit" className="top" />
              <h1 className="center">Waiter incoming</h1>
            </div>
          </div>
        );
        break;
      case "ว่าง":

        sessionStorage.removeItem("table");
        break;
    }

    return this.props.status === "เก็บเงิน" || this.props.status === "ปริ๊น"
      ? bill
      : "";
  }
}

const mapDispatchToProps = dispatch => {
  return {};
};

const mapStateToProps = state => {
  return {
    status: state.tab.status
  };
};
export default compose(
  firestoreConnect(["cart", "tab"]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Bill);
