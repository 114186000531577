import React, { Component } from "react";
import Header from "./components/Header";
import Products from "./components/Products";
import QuickView from "./components/QuickView";

import Bill from "./components/Bill";

class Restaurant extends Component {
  render() {    
    let bill = sessionStorage.getItem("table") !== null ? <Bill /> : "";
    return (
      <div className="Store">
        <Header />
        <Products />
        <QuickView />
        {bill}
      </div>
    );
  }
}

export default Restaurant;
