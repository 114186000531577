const initstate = {
  promotion: [
    { type: "discount", foodid: "ap00101", discount: "20%" },
    { type: "item",itemid:"ml00101",quantity:3 }
  ]
};

export default function(state = initstate, action) {
  switch (action.type) {
    case "CREATE_PROMO":
      return {
        ...state,
        items: action.payload
      };
    case "FETCH_PROMO":
      return {
        ...state,
        items: action.payload
      };

    default:
      return state;
  }
}
