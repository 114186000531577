import React, { Component } from "react";
import CartScrollBar from "./CartScrollBar";
import EmptyCart from "../empty-states/EmptyCart";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { findDOMNode } from "react-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { db } from "../../../config/fbConfig";
import { Icon, Switch } from "antd";
import { setTerm, resetTerm } from "../../../store/action/orderAction";
import { setTab, setStatus } from "../../../store/action/tabAction";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCart: false,
      mobileSearch: false,
      buttonText: "สั่งซื้อ",
      isChecked: true
    };
  }

  handleCart(e) {
    e.preventDefault();
    this.setState({
      showCart: !this.state.showCart
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      mobileSearch: false
    });
  }
  handleMobileSearch = e => {
    e.preventDefault();
    this.setState({
      mobileSearch: true
    });
  };
  handleSearchNav(e) {
    e.preventDefault();
    this.setState(
      {
        mobileSearch: false
      },
      function() {
        this.refs.searchBox.value = "";
        this.props.resetTerm();
      }
    );
  }
  handleClickOutside(event) {
    const cartNode = findDOMNode(this.refs.cartPreview);
    if (cartNode.classList.contains("active")) {
      if (!cartNode || !cartNode.contains(event.target)) {
        this.setState({
          showCart: false
        });
        event.stopPropagation();
      }
    }
  }
  addToOrder() {
    let tab= this.props.allTab.find(data=>{return data.name === this.props.tab})
    if (this.state.isChecked) {
      console.log("fc");
      
      let newOrder = Object.keys(this.props.order[this.props.tab]).length + 1;
      if (this.props.cart) {
        db.collection("order")
          .doc(this.props.tab)
          .update({
            [newOrder]: this.props.cart[this.props.tab]
          });
        db.collection("cart")
          .doc(this.props.tab)
          .set({});
      }

      if (this.props.tab.status === "ว่าง") {
        db.collection("tab")
          .doc(tab.id)
          .update({
            status: "มีลูกค้า"
          });
      }
      setTimeout(() => {
        this.setState({
          isChecked: !this.state.isChecked,
          buttonText: "เก็บเงิน"
        });
      }, 700);
      this.setState({
        buttonText: "สั่งซื้อสำเร็จ"
      });
    } else {
      
      db.collection("tab")
          .doc(tab.id)
          .update({
            status: "เก็บเงิน"
          });
    }
  }
  removeProduct(productid) {
    let product = {};
    var j = 1;
    for (var i in this.props.cart[this.props.tab]) {
      if (!(i === productid)) {
        product[j] = this.props.cart[this.props.tab][i];
        j++;
      }
    }
    db.collection("cart")
      .doc(this.props.tab)
      .set(product);
  }
  checked = () => {
let boo = !this.state.isChecked
    this.setState({
      isChecked: boo
    });
    this.setState({
      buttonText: boo ? "สั่งซื้อ" : "เก็บเงิน"
    });
  };
  render() {
    let allTab = this.props.allTab ? this.props.allTab : [];
    this.props.setTab(sessionStorage.getItem("table"));
    let tab = allTab.findIndex(data => {
      return data.name === this.props.tab;
    });

    this.props.setStatus(allTab[tab] ? allTab[tab].status : "");

    let orderItem = [];
    let cartItem = [];
    let cartTotal = 0,
      cartSumTotal = 0,
      orderTotal = 0,
      orderSumTotal = 0;
    let { order, orderLoad, cart, cartLoad, food, foodLoad } = this.props;
    let orders = order ? order[this.props.tab] : orderLoad;
    let carts = cart ? cart[this.props.tab] : cartLoad;
    let foods = food ? food : foodLoad;
    function searchFoodImage(id) {
      for (var i in foods) {
        if (foods[i].id === id) {
          return foods[i].image;
        }
      }
    }
    for (var i in carts) {
      let foodItem = foods.find(data => {
        return data.id === carts[i].id;
      })
        ? foods.find(data => {
            return data.id === carts[i].id;
          })
        : "";

      cartTotal += 1;
      cartSumTotal += foodItem.price;
      cartItem.push(
        <CSSTransition
          timeout={300}
          in={true}
          className="cart-item"
          key={foodItem.name}
        >
          <div key={"cart" + carts[i].id}>
            <img
              className="product-image"
              src={searchFoodImage(carts[i].id)}
              alt={carts[i].id}
            />
            <div className="product-info">
              <p className="product-name">{foodItem.name}</p>
              <p className="product-price">{foodItem.price}</p>
            </div>
            <div className="product-total">
              <p className="quantity">
                {carts[i].quantity} {carts[i].quantity > 1 ? "Nos." : "No."}{" "}
              </p>
              <p className="amount">{carts[i].quantity * foodItem.price}</p>
            </div>
            <Icon
              type="close"
              className="product-remove"
              onClick={this.removeProduct.bind(this, i)}
            />
          </div>
        </CSSTransition>
      );
    }
    for (var i in orders) {
      let no = parseInt(i);
      for (var j in orders[no]) {
        let foodItem = foods.find(data => {
          return data.id === orders[no][j].id;
        })
          ? foods.find(data => {
              return data.id === orders[no][j].id;
            })
          : "";
        orderTotal += 1;
        orderSumTotal += foodItem.price;
        orderItem.push(
          <CSSTransition
            timeout={300}
            in={true}
            className="cart-item"
            key={"order" + no + "-" + orders[no][j].id}
          >
            <div key={"order" + no + "-" + orders[no][j].id}>
              <img
                className="product-image"
                src={searchFoodImage(orders[no][j].id)}
                alt={orders[no][j].id}
              />
              <div className="product-info">
                <p className="product-no">Order no. {no}</p>
                <p className="product-name">{foodItem.name}</p>
                <p className="product-price">{foodItem.price}</p>
              </div>
              <div className="product-total">
                <p className="quantity">
                  {orders[no][j].quantity}{" "}
                  {orders[no][j].quantity > 1 ? "Nos." : "No."}{" "}
                </p>
                <p className="amount">
                  {orders[no][j].quantity * foodItem.price}
                </p>
              </div>
            </div>
          </CSSTransition>
        );
      }
    }
    function isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }

    let itemList = this.state.isChecked ? cartItem : orderItem;
    let itemTotal = this.state.isChecked ? cartTotal : orderTotal;
    let itemSumTotal = this.state.isChecked ? cartSumTotal : orderSumTotal;
    let view;
    if (isEmpty(itemList)) {
      view = <EmptyCart />;
    } else {
      view = (
        <TransitionGroup>
          <div className="cart-items">{itemList}</div>
        </TransitionGroup>
      );
    }
    return (
      <header>
        <div className="container">
          <div className="brand">
            <img
              className="logo"
              src="https://firebasestorage.googleapis.com/v0/b/dairyhome-dcea9.appspot.com/o/image%2Ficon.png?alt=media&token=87a82db3-5628-401c-a29f-e6ced039a73b"
              alt="dairyhome Brand Logo"
              onClick={this.props.resetTerm}
            />
          </div>
          <div className="head-tab">{this.props.tab}</div>
          <div className="search">
            <a className="mobile-search" onClick={this.handleMobileSearch}>
              <Icon type="search" className="center" />
            </a>
            <form
              method="get"
              className={
                this.state.mobileSearch ? "search-form active" : "search-form"
              }
            >
              <a
                className="back-button"
                onClick={this.handleSearchNav.bind(this)}
              >
                <Icon type="left" />
              </a>
              <input
                type="search"
                ref="searchBox"
                placeholder="Search for food"
                className="search-keyword"
                onChange={this.props.setTerm}
              />
              <button
                className="search-button"
                type="submit"
                onClick={this.handleSubmit.bind(this)}
              >
                <Icon type="search" />
              </button>
            </form>
          </div>

          <div className="cart">
            <div className="cart-info">
              <table>
                <tbody>
                  <tr>
                    <td>No. of items</td>
                    <td>:</td>
                    <td>
                      <strong>{itemTotal}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Sub Total</td>
                    <td>:</td>
                    <td>
                      <strong>{itemSumTotal}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Table no.</td>
                    <td>:</td>
                    <td>
                      <strong>{this.props.tab}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <a
              className="cart-icon"
              onClick={this.handleCart.bind(this)}
              ref="cartButton"
            >
              <Icon type="shopping-cart" />
              {itemTotal ? <span className="cart-count">{itemTotal}</span> : ""}
            </a>
            <div
              className={
                this.state.showCart ? "cart-preview active" : "cart-preview"
              }
              ref="cartPreview"
            >
              <Switch
                checkedChildren={<Icon type="shopping-cart" />}
                unCheckedChildren={<Icon type="bars" />}
                onChange={this.checked}
                defaultChecked
                checked={this.state.isChecked}
                className="cart-switch"
              />
              <CartScrollBar>{view}</CartScrollBar>
              <div className="action-block">
                <button type="button" onClick={this.addToOrder.bind(this)}>
                  {this.state.buttonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderLoad: state.order.ord,
    order: state.firestore.data.order,
    cartLoad: state.order.cart,
    cart: state.firestore.data.cart,
    foodLoad: state.food.foods,
    food: state.firestore.ordered.food,
    totalItems: state.order.totalItems,
    tab: state.tab.currentTab,
    allTab: state.firestore.ordered.tab
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setTerm: term => dispatch(setTerm(term)),
    resetTerm: () => dispatch(resetTerm()),
    setTab: tab => dispatch(setTab(tab)),
    setStatus: status => dispatch(setStatus(status))
  };
};

export default compose(
  firestoreConnect(["order", "cart", "food", "tab"]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Header);
