const initstate = {
  cart: [],
  ord: [],
  totalItems: 0,
  totalAmount: 0,
  term: "",
  category: "",
  cartBounce: false,
  quantity: 1,
  quickViewProduct: {},
  modalActive: false,
  overlay: false,
  currentFood: {}
};

export default function(state = initstate, action) {
  switch (action.type) {
    case "SET_TERM":
      return {
        ...state,
        term: action.term
      };
    case "RESET_TERM":
      return {
        ...state,
        term: ""
      };
    case "OPEN_MODAL":
      return {
        ...state,
        quickViewProduct: action.product,
        modalActive: true
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modalActive: false
      };
    default:
      return state;
  }
}
