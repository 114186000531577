import React, { Component } from "react";
import QrReader from "react-qr-reader";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { setTab } from "../../../store/action/tabAction";
import { db } from "../../../config/fbConfig";
import { Result, Icon, Button } from "antd";

class QrScanner extends Component {
  state = { isViewQr: false };
  handleScan = data => {
    for (var i in this.props.tab) {
      if (data === this.props.tab[i].qrcode) {
        sessionStorage.setItem("table", this.props.tab[i].name);
        this.props.setTab(this.props.tab[i].name);
        if (this.props.tab[i].status === "ว่าง") {
          db.collection("tab")
            .doc(this.props.tab[i].id)
            .update({ status: "มีลูกค้า" })
            .then(() => {
              this.props.moveToRestaurant();
            });
        }
      }
    }
  };
  handleError = err => {
    console.error(err);
  };
  click = () => {
    this.setState({ isViewQr: true });
  };
  render() {
    let view = this.state.isViewQr ? (
      <div>
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          className="qr-screen"
        />
      </div>
    ) : (
      <Result
        icon={
          <Icon type="security-scan" theme="twoTone" twoToneColor="#F79C2D" />
        }
        title="Please accept camera access to scan QR code"
        extra={
          <Button type="primary" onClick={this.click}>
            Ok
          </Button>
        }
      />
    );
    return (
      <div className="qr-scan">
        {this.props.currentTab !== "" ? (
          <div className="qr-get-tab bill-bg">{this.props.currentTab}</div>
        ) : (
          view
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTab: tab => dispatch(setTab(tab))
  };
};

const mapStateToProps = state => {
  return {
    tab: state.firestore.ordered.tab,
    currentTab: state.tab.currentTab
  };
};
export default compose(
  firestoreConnect(["tab"]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QrScanner);
