import React, { Component } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Product from "./Product";
import LoadingProducts from "../loaders/Products";
import NoResults from "../empty-states/NoResults";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  createOrder,
  changeOverlay,
  setCurrentFood
} from "../../../store/action/orderAction";

class Products extends Component {
  render() {
    let { food, foodLoad } = this.props;
    let foods = food ? food : foodLoad;
    let { term } = this.props;
    let x;
    function searchingFor(term) {
      return function(x) {
        return (
          x.name.toLowerCase().includes(term.toLowerCase()) ||
          x.category.toLowerCase().includes(term.toLowerCase()) ||
          !term
        );
      };
    }
    let productsData = foods.filter(searchingFor(term)).map(product => {
      return (
        <CSSTransition timeout={500} key={product.id} classNames="fadeIn">
          <Product
            key={product.id}
            price={product.price}
            name={product.name}
            image={product.image}
            id={product.id}
            category={product.category}
          />
        </CSSTransition>
      );
    });
    // Empty and Loading States
    let view;
    if (productsData.length <= 0 && !term) {
      view = <LoadingProducts />;
    } else if (productsData.length <= 0 && term) {
      view = <NoResults />;
    } else {
      view = (
        <TransitionGroup timeout={500} component="div" className="products">
          {productsData}
        </TransitionGroup>
      );
    }
    return this.props.status === "มีลูกค้า" ? (
      <div className="products-wrapper">{view}</div>
    ) : (
      ""
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createOrder: order => dispatch(createOrder(order)),
    changeOverlay: () => dispatch(changeOverlay()),
    setCurrentFood: food => dispatch(setCurrentFood(food))
  };
};

const mapStateToProps = state => {
  return {
    foodLoad: state.food.foods,
    categoryLoad: state.food.category,
    term: state.order.term,
    food: state.firestore.ordered.food,
    category: state.firestore.ordered["food-category"],
    status: state.tab.status
  };
};
export default compose(
  firestoreConnect(["food", "food-category"]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Products);
