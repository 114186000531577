import React, { Component } from "react";
import { Skeleton, Layout } from "antd";
import Header from "./store/Header";

const { Content } = Layout;

class Home extends Component {
  render() {
    return (
      <div className="web-home">
        <Layout>
          <Header />
          <Content>
            <Skeleton active />
          </Content>
        </Layout>
      </div>
    );
  }
}
export default Home;
