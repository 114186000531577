import React, { Component } from "react";
import { findDOMNode } from "react-dom";

import { connect } from "react-redux";
import { openModal, closeModal,setTermCategory } from "../../../store/action/orderAction";

class QuickView extends Component {
  componentDidMount() {
    document.addEventListener(
      "click",
      this.handleClickOutside.bind(this),
      true
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "click",
      this.handleClickOutside.bind(this),
      true
    );
  }

  handleClickOutside(event) {
    const domNode = findDOMNode(this.refs.modal);
    if (!domNode || !domNode.contains(event.target)) {
      this.props.closeModal();
    }
  }

  handleClose() {
    this.props.closeModal();
  }
  termChange(term){
    console.log(term)
    this.props.setTermCategory(term);
    this.props.closeModal();

  }
  render() {
    return (
      <div
        className={
          this.props.modalActive ? "modal-wrapper active" : "modal-wrapper"
        }
      >
        <div className="modal" ref="modal">
          <button
            type="button"
            className="close"
            onClick={this.handleClose.bind(this)}
          >
            &times;
          </button>
          <div className="quick-view">
            <div className="quick-view-image">
              <img
                src={this.props.quickViewProduct.image}
                alt={this.props.quickViewProduct.name}
              />
            </div>
            <div className="quick-view-details">
              <span className="product-name">{this.props.quickViewProduct.name}</span>
              <span className="product-price">{this.props.quickViewProduct.price}</span>
              <div className="product-category">
                <button
                  className={this.props.quickViewProduct.category}
                  type="button"
                  onClick={this.termChange.bind(this,this.props.quickViewProduct.category)}
                >
                  {this.props.quickViewProduct.category}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    openModal: product => dispatch(openModal(product)),
    closeModal: () => dispatch(closeModal()),
    setTermCategory:(term)=>dispatch(setTermCategory(term))
  };
};

const mapStateToProps = state => {
  return {
    quickViewProduct: state.order.quickViewProduct,
    modalActive: state.order.modalActive,
    term: state.order.term
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickView);
