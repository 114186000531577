const initstate = {
  foods: [],
  category: [
    { name: "กับข้าว", name_alt: "Side dish" },
    {
      name: "สเต็ก",
      name_alt: "steak",
      level: ["Rare", "Medium Rare", "Medium", "Medium well done", "Well done"]
    },
    { name: "ทานเล่น", name_alt: "appetizer" }
  ]
};

export default function(state = initstate, action) {
  switch (action.type) {
    case "CREATE_FOOD":
      return {
        ...state,
        items: action.payload
      };
    case "FETCH_FOOD":
      return {
        ...state,
        items: action.payload
      };
    default:
      return state;
  }
}
