import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import ReactDOM from "react-dom";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reduxFirestore } from "redux-firestore";
import { reactReduxFirebase } from "react-redux-firebase";
import rootReducer from "./store/reducers/rootReducer";
import firebase from "./config/fbConfig";
import thunk from "redux-thunk";

import Home from "./components/Home";
import QrScanner from "./components/restaurant/components/QrScanner";

import "antd/dist/antd.css";
import "./scss/style.scss";
import Restaurant from "./components/restaurant/Restaurant";

const store = createStore(
  rootReducer,
  composeWithDevTools(
    reactReduxFirebase(firebase),
    reduxFirestore(firebase),
    applyMiddleware(thunk)
  )
);

class App extends Component {
  state = {
    isSetTab: sessionStorage.getItem("table")!==null
  };
  handleMoveToRestaurant = () => {
    this.setState({ isSetTab: true });
  };
  render() {
    return (
      <BrowserRouter>
        <Route exact path="/" component={Home} />
        <Route
          path="/restaurant"
          render={() =>
            this.state.isSetTab ? (
              <Restaurant />
            ) : (
              <QrScanner moveToRestaurant={this.handleMoveToRestaurant} />
            )
          }
        />
      </BrowserRouter>
    );
  }
}
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
